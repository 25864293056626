<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  :label="formData.title"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="formData.title"
                    rules=""
                    vid="value"
                >

                  <b-input-group v-if="formData.type !== 'editor'">

                    <b-form-input v-if="formData.type === 'integer'"
                                  v-model="formData.value"
                                  type="number"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="formData.title"
                    />

                    <b-form-input v-if="formData.type === 'text'"
                                  v-model="formData.value"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="formData.title"
                    />

                    <b-form-input v-if="formData.type === 'date'"
                                  v-model="formData.value"
                                  type="text"
                                  placeholder="ÉÉÉÉ-HH-NN"
                                  autocomplete="off"
                                  :formatter="formatDateAllowedSymbols"
                                  show-decade-nav
                                  required
                    />

                    <b-form-input v-if="formData.type === 'time'"
                                  v-model="formData.value"
                                  type="time"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="formData.title"
                                  :formatter="formatTimeAllowedSymbols"
                    />

                    <flat-pickr v-if="formData.type === 'datetime'"
                                v-model="formData.value"
                                class="form-control"
                                :config="flatPickRConfig"
                    />

                    <b-input-group-append v-if="formData.type === 'date'">
                      <b-form-datepicker
                          v-model="formData.value"
                          size="sm"
                          show-decade-nav
                          button-only
                          right
                          :locale="$i18n.locale"
                          v-bind="labels[$i18n.locale] || {}"
                      />
                    </b-input-group-append>

                    <b-input-group-append v-if="formData.type === 'time'">
                      <b-form-timepicker
                          v-model="formData.value"
                          size="sm"
                          button-only
                          right
                          :locale="$i18n.locale"
                          v-bind="labels[$i18n.locale] || {}"
                      />
                    </b-input-group-append>

                    <v-select v-if="formData.type === 'select'"
                              v-model="formData.value"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="title"
                              :options="valueOptions"
                    />

                    <b-form-radio-group v-if="formData.type === 'radio'"
                                        id="value-radio-group"
                                        v-model="formData.value"
                                        :options="valueOptions"
                    />

                    <b-form-checkbox v-if="formData.type === 'checkbox'"
                        v-model="formData.value"
                        :state="errors.length > 0 ? false:null"
                    >
                     {{ formData.title }}
                    </b-form-checkbox>

                    <b-form-file v-if="formData.type === 'image'"
                        v-model="formData.value"
                        placeholder="Válassz vagy húzd ide a fájlt"
                        drop-placeholder="A fájlt húzd ide..."
                        :browse-text="$t('label.browse')"
                        accept="
                        image/bmp,
                        image/gif,
                        image/jpeg,
                        image/png,
                        image/webp
                      "
                    />

                    <b-form-textarea v-if="formData.type === 'textarea'"
                        v-model="formData.value"
                    ></b-form-textarea>

                  </b-input-group>

                    <editor
                        :show="showEditor"
                        v-model="formData.value"
                    />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12" class="mt-2" v-if="formData.type === 'image'">
              <img style="max-width: 900px" :src="formData.imageUrl" :alt="formData.title" class="img-thumbnail">
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTimepicker,
  BFormRadioGroup,
  BFormTextarea,
    BFormFile,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_option/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import editor from '@/views/pages/_components/editor.vue'
import labels from '@/libs/datepicker/labels'
import {formatDateAllowedSymbols, formatTimeAllowedSymbols} from "@core/utils/filter";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTimepicker,
    BFormRadioGroup,
    BFormTextarea,
    BFormFile,
    vSelect,
    tabs,
    editor,
    cancelAndGOTOPreviousPage,
    flatPickr
  },
  data() {
    return {
      loading: true,
      role: 'option',
      formData: {
        id: null,
        type: '',
        title: '',
        key: '',
        value: '',
        options: '',
        description: '',
      },
      valueOptions: [],
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      labels,
      formatDateAllowedSymbols,
      formatTimeAllowedSymbols,
      required,
      email,
    }
  },
  computed: {
    showEditor() {
      return this.formData.type === 'editor' && !this.loading
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchOption', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id

      if (response.data.entity.type === 'select' || response.data.entity.type === 'radio') {
        if(this.isJsonValid(response.data.entity.options)){
          this.valueOptions = JSON.parse(response.data.entity.options)
        }else{
          this.$store.dispatch(response.data.entity.options).then(response => {
            this.valueOptions = response
          })
        }
      }

      if (response.data.entity.type === 'select') {
        Object.keys(this.valueOptions).forEach(key => {
          if (this.valueOptions[key].value === response.data.entity.value) {
            this.formData.value = this.valueOptions[key];
          }
        })
      }
      this.loading = false
    }).catch(error => {
      this.$helpers.showErrorToast(error.response.data.message)
      this.$acl.canListPermission(this.role)
          ? this.$router.push({name: 'options'})
          : this.$router.go(-1)
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        let fd;
        if (success) {
          if (this.formData.type !== 'image') {
            fd = {...this.formData}
            if (this.formData.type === 'select') {
              fd.value = this.formData.value.value
            }
          }else{
            fd = new FormData();
            fd.append('id', this.$route.params.id)
            fd.append('type', this.formData.type)
            fd.append('title', this.formData.title)
            fd.append('key', this.formData.key)
            fd.append('options', this.formData.options)
            fd.append('description', this.formData.description)
            fd.append('value', this.formData.value)
          }

          this.$store.dispatch('updateOption', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'options'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    // TODO: @Kristóf tedd helperbe
    isJsonValid(value){
      if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
      replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
      replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return true
      }

      return false
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.v-select {
  width: 100%;
}
</style>
